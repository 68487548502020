import React from "react";

import ContactUsBlock from "@components/contact/contactUsBlock";
import SEO from "@components/shared/seo";
import CraOrdersHero from "@components/craOrders/craOrdersHero";

import { Container, H2 } from "@util/standard";

export default function CraOrders() {
  return (
    <div>
      <SEO />
      <CraOrdersHero />
      <Container width="70%" margin="150px auto">
        <H2>Coming Soon</H2>
      </Container>
      <ContactUsBlock />
    </div>
  );
}
