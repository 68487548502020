import React from "react";
import styled from "styled-components";

import { colors, pages } from "@util/constants";
import { Container, H1 } from "@util/standard";
import { Button } from "@sub";

const Wrapper = styled(Container)`
  width: 100%;
  padding: 100px 0;
  background-color: ${colors.lightBlue};
  justify-content: center;
  align-items: center;
`;

const ContactUsBlock = () => {
  return (
    <Wrapper>
      <Container flexDirection="column">
        <H1>Contact Us</H1>
        <Button theme="base" text="Find out more" linkTo={pages.contactUs} />
      </Container>
    </Wrapper>
  );
};

export default ContactUsBlock;
