import React from "react";
import styled from "styled-components";
import { FluidObject } from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

import { Button, Image, RichTextContent } from "@sub";
import { Container, H1, H3, Separator } from "@util/standard";
import { colorsRGB, MOBILE_BREAKPOINT } from "@util/constants";
import {
  Maybe,
  Query,
  SanityImage,
  SanityLink,
  SanityRichTextBlock,
} from "@graphql-types";

const Wrapper = styled(Container)<{
  height?: string;
}>`
  position: relative;
  width: 100%;
  height: ${(props) => props.height ?? "80vh"};
  margin: 0;
`;

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  transition: 0.5s;
  background-color: ${colorsRGB.black(0.63)};
  overflow: hidden;
`;

const ContentContainer = styled(Container)`
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

const CtaContainer = styled(Container)`
  margin: 25px 0 0 0;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

interface Props {
  image?: Maybe<SanityImage> | undefined;
  height?: string;
  content?: Maybe<SanityRichTextBlock> | undefined;
  links?: Maybe<Maybe<SanityLink>[]> | undefined;
  title?: string;
}

const Hero = (props: Props) => {
  const { sanitySiteConfig }: Query = useStaticQuery(graphql`
    {
      sanitySiteConfig {
        heroImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `);

  return (
    <Wrapper height={props.height}>
      <Overlay />
      <Image
        altText="hero-image"
        fluid={
          props.image
            ? (props.image?.asset?.fluid as FluidObject)
            : (sanitySiteConfig?.heroImage?.asset?.fluid as FluidObject)
        }
        isBackground
        backgroundStyle={{
          width: "100%",
        }}
      />

      <ContentContainer>
        <Container width="70%" margin="auto" flexDirection="column">
          <H3 margin="0" color="white">
            CRA
          </H3>
          <Separator color="white" dimensions={{ width: "130px" }} />

          {props.title ? (
            <H1 noMargin color="white">
              {props.title}
            </H1>
          ) : (
            <RichTextContent
              color="white"
              blocks={props.content?._rawRichTextContent}
            />
          )}
          <CtaContainer>
            {props.links?.map((cta) => {
              if (cta == null) {
                return null;
              }
              return (
                <Button
                  key={cta._key}
                  theme="white"
                  text={cta?.linktext ?? ""}
                  linkTo={cta?.url ?? "/"}
                  margin="0 20px 10px 0"
                  dimensions={{ height: "46px" }}
                />
              );
            })}
          </CtaContainer>
        </Container>
      </ContentContainer>
    </Wrapper>
  );
};

export default Hero;
